import React, { useState, useEffect } from "react";
import axios from "axios";
import {
  Window,
  WindowContent,
  WindowHeader,
  Button,
  GroupBox,
  Table,
  TableBody,
  TableRow,
  TableHead,
  TableDataCell,
  TableHeadCell,
  TextField,
  Tabs,
  Tab,
  TabBody,
} from "react95";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL || "";

// Styled components for admin page
const AdminPageWrapper = styled.div`
  padding-top: 2rem;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const AdminWindow = styled(Window)`
  width: 90%;
  max-width: 1200px;
  margin: 20px 0;
`;

const WindowHeaderStyled = styled(WindowHeader)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 8px;
`;

const CenteredTextField = styled(TextField)`
  margin-bottom: 10px;
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 50%;
`;

// Scrollable table container with fixed table headers and hidden scrollbar
const ScrollableTable = styled.div`
  max-height: 600px;
  overflow-y: auto; /* Hides the vertical scrollbar visually */
  position: relative;
  -ms-overflow-style: none; /* Internet Explorer 10+ */
  scrollbar-width: none; /* Firefox */

  &::-webkit-scrollbar {
    display: none; /* Hides scrollbar in webkit-based browsers */
  }
`;

const StyledTable = styled(Table)`
  width: 100%;
  border-collapse: collapse;
  overflow: auto; /* Allows scrolling without showing scrollbar */

  thead {
    position: sticky;
    top: 0;
    z-index: 1;
  }

  th,
  td {
    padding: 8px 12px;
    text-align: left;
    border-bottom: 1px solid #ddd;
  }
`;

const DashboardPage = () => {
  const [competitions, setCompetitions] = useState([]);
  const [users, setUsers] = useState([]);
  const [transactions, setTransactions] = useState([]);
  const [userJackpotData, setUserJackpotData] = useState({});
  const [entries, setEntries] = useState([]);
  const [activeTab, setActiveTab] = useState(0); // Active tab state

  const [searchCompetitions, setSearchCompetitions] = useState("");
  const [searchUsers, setSearchUsers] = useState("");
  const [searchTransactions, setSearchTransactions] = useState("");

  const [sortConfig, setSortConfig] = useState({
    key: "id",
    ascending: false,
  });

  const navigate = useNavigate();

  // Fetch data for all tabs
  useEffect(() => {
    const fetchData = async () => {
      try {
        // Updated to use API_BASE_URL
        const competitionsResponse = await axios.get(
          `${API_BASE_URL}/competitions`
        );
        const usersResponse = await axios.get(`${API_BASE_URL}/api/users`);
        const transactionsResponse = await axios.get(
          `${API_BASE_URL}/api/transactions`
        );

        setCompetitions(competitionsResponse.data);
        setUsers(usersResponse.data);
        setTransactions(transactionsResponse.data);

        console.log("Competitions data:", competitionsResponse.data);
        console.log("Users data:", usersResponse.data);
        console.log("Transactions data:", transactionsResponse.data);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);

  // Fetch entries for competitions
  useEffect(() => {
    const fetchEntries = async () => {
      try {
        const entriesPromises = competitions.map((competition) =>
          // Updated to use API_BASE_URL
          axios.get(`${API_BASE_URL}/competitions/${competition.id}/entries`)
        );

        const entriesResponses = await Promise.all(entriesPromises);
        const allEntries = entriesResponses.flatMap(
          (response) => response.data
        );

        setEntries(allEntries);
        console.log("Entries data:", allEntries);
      } catch (error) {
        console.error("Error fetching entries:", error);
      }
    };

    if (competitions.length > 0) {
      fetchEntries();
    }
  }, [competitions]);

  // Fetch global jackpot data for users
  useEffect(() => {
    const fetchUserJackpotData = async () => {
      try {
        const promises = users.map((user) =>
          // Updated to use API_BASE_URL
          axios.get(
            `${API_BASE_URL}/api/users/${user.wallet_address}/global-jackpot`
          )
        );

        const responses = await Promise.all(promises);
        const jackpotData = responses.reduce((acc, response, index) => {
          acc[users[index].wallet_address] = response.data.tickets || 0;
          return acc;
        }, {});

        setUserJackpotData(jackpotData);
      } catch (error) {
        console.error("Error fetching user jackpot data:", error);
      }
    };

    if (users.length > 0) {
      fetchUserJackpotData();
    }
  }, [users]);

  // Handle sorting
  const handleSort = (column) => {
    let ascending = sortConfig.key === column ? !sortConfig.ascending : true;
    setSortConfig({ key: column, ascending });
  };

  // Helper function for sorting
  const sortData = (data, column, ascending) => {
    const sortedData = [...data].sort((a, b) => {
      if (a[column] < b[column]) return ascending ? -1 : 1;
      if (a[column] > b[column]) return ascending ? 1 : -1;
      return 0;
    });
    return sortedData;
  };

  // Filter and sort data
  const filteredCompetitions = sortData(
    competitions.filter((comp) =>
      comp.token_name.toLowerCase().includes(searchCompetitions.toLowerCase())
    ),
    sortConfig.key,
    sortConfig.ascending
  );

  const filteredUsers = sortData(
    users.filter((user) =>
      user.wallet_address.toLowerCase().includes(searchUsers.toLowerCase())
    ),
    sortConfig.key,
    sortConfig.ascending
  );

  const filteredTransactions = sortData(
    transactions.filter((transaction) =>
      transaction.user_id
        .toString()
        .toLowerCase()
        .includes(searchTransactions.toLowerCase())
    ),
    sortConfig.key,
    sortConfig.ascending
  );

  // Calculate total tickets sold and unique participants for each competition
  const getTotalTicketsAndParticipants = (competitionId) => {
    const competitionEntries = entries.filter(
      (entry) => entry.competition_id === competitionId
    );
    const totalTicketsSold = competitionEntries.reduce(
      (acc, entry) => acc + entry.ticket_count,
      0
    );
    const uniqueParticipants = new Set(
      competitionEntries.map((entry) => entry.user_id)
    ).size;

    return {
      totalTicketsSold,
      uniqueParticipants,
    };
  };

  // Handle tab change
  const handleChangeTab = (value) => {
    setActiveTab(value);
  };

  return (
    <AdminPageWrapper>
      <AdminWindow>
        <WindowHeaderStyled>
          <span>AdminDashboard.exe</span>
          <Button onClick={() => navigate(-1)}>X</Button>
        </WindowHeaderStyled>
        <WindowContent>
          <Tabs value={activeTab} onChange={(value) => handleChangeTab(value)}>
            <Tab value={0}>Competitions</Tab>
            <Tab value={1}>Users</Tab>
            <Tab value={2}>Transactions</Tab>
          </Tabs>

          <TabBody>
            {activeTab === 0 && (
              <GroupBox label="Competitions">
                <CenteredTextField
                  placeholder="Search Competitions..."
                  value={searchCompetitions}
                  onChange={(e) => setSearchCompetitions(e.target.value)}
                />
                <ScrollableTable>
                  <StyledTable>
                    <TableHead>
                      <TableRow>
                        <TableHeadCell onClick={() => handleSort("id")}>
                          ID
                        </TableHeadCell>
                        <TableHeadCell onClick={() => handleSort("token_name")}>
                          Name
                        </TableHeadCell>
                        <TableHeadCell onClick={() => handleSort("status")}>
                          Status
                        </TableHeadCell>
                        <TableHeadCell onClick={() => handleSort("prize_pool")}>
                          Prize Pool
                        </TableHeadCell>
                        <TableHeadCell onClick={() => handleSort("createdAt")}>
                          Creation Time
                        </TableHeadCell>
                        <TableHeadCell onClick={() => handleSort("end_time")}>
                          End Time
                        </TableHeadCell>
                        <TableHeadCell
                          onClick={() => handleSort("tickets_sold")}
                        >
                          Tickets Sold
                        </TableHeadCell>
                        <TableHeadCell
                          onClick={() => handleSort("participants")}
                        >
                          Participants
                        </TableHeadCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {filteredCompetitions.map((competition) => {
                        const { totalTicketsSold, uniqueParticipants } =
                          getTotalTicketsAndParticipants(competition.id);

                        return (
                          <TableRow key={competition.id}>
                            <TableDataCell>{competition.id}</TableDataCell>
                            <TableDataCell>
                              {competition.token_name}
                            </TableDataCell>
                            <TableDataCell>{competition.status}</TableDataCell>
                            <TableDataCell>
                              {Number(competition.prize_pool).toFixed(2)} SOL
                            </TableDataCell>
                            <TableDataCell>
                              {new Date(
                                competition.createdAt
                              ).toLocaleString() || "N/A"}
                            </TableDataCell>
                            <TableDataCell>
                              {new Date(
                                competition.end_time
                              ).toLocaleString() || "N/A"}
                            </TableDataCell>
                            <TableDataCell>{totalTicketsSold}</TableDataCell>
                            <TableDataCell>{uniqueParticipants}</TableDataCell>
                          </TableRow>
                        );
                      })}
                    </TableBody>
                  </StyledTable>
                </ScrollableTable>
              </GroupBox>
            )}
            {activeTab === 1 && (
              <GroupBox label="Users">
                <CenteredTextField
                  placeholder="Search Users..."
                  value={searchUsers}
                  onChange={(e) => setSearchUsers(e.target.value)}
                />
                <ScrollableTable>
                  <StyledTable>
                    <TableHead>
                      <TableRow>
                        <TableHeadCell onClick={() => handleSort("id")}>
                          ID
                        </TableHeadCell>
                        <TableHeadCell
                          onClick={() => handleSort("wallet_address")}
                        >
                          Wallet Address
                        </TableHeadCell>
                        <TableHeadCell onClick={() => handleSort("balance")}>
                          Balance
                        </TableHeadCell>
                        <TableHeadCell
                          onClick={() => handleSort("global_jackpot_tickets")}
                        >
                          Global Jackpot Tickets
                        </TableHeadCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {filteredUsers.map((user) => (
                        <TableRow key={user.id}>
                          <TableDataCell>{user.id}</TableDataCell>
                          <TableDataCell>{user.wallet_address}</TableDataCell>
                          <TableDataCell>
                            {Number(user.balance).toFixed(2)} SOL
                          </TableDataCell>
                          <TableDataCell>
                            {userJackpotData[user.wallet_address] || 0}
                          </TableDataCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </StyledTable>
                </ScrollableTable>
              </GroupBox>
            )}
            {activeTab === 2 && (
              <GroupBox label="Transactions">
                <CenteredTextField
                  placeholder="Search Transactions..."
                  value={searchTransactions}
                  onChange={(e) => setSearchTransactions(e.target.value)}
                />
                <ScrollableTable>
                  <StyledTable>
                    <TableHead>
                      <TableRow>
                        <TableHeadCell onClick={() => handleSort("id")}>
                          ID
                        </TableHeadCell>
                        <TableHeadCell onClick={() => handleSort("user_id")}>
                          User ID
                        </TableHeadCell>
                        <TableHeadCell onClick={() => handleSort("amount")}>
                          Amount
                        </TableHeadCell>
                        <TableHeadCell onClick={() => handleSort("type")}>
                          Type
                        </TableHeadCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {filteredTransactions.map((transaction) => (
                        <TableRow key={transaction.id}>
                          <TableDataCell>{transaction.id}</TableDataCell>
                          <TableDataCell>{transaction.user_id}</TableDataCell>
                          <TableDataCell>
                            {Number(transaction.amount).toFixed(2)} SOL
                          </TableDataCell>
                          <TableDataCell>{transaction.type}</TableDataCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </StyledTable>
                </ScrollableTable>
              </GroupBox>
            )}
          </TabBody>
        </WindowContent>
      </AdminWindow>
    </AdminPageWrapper>
  );
};

export default DashboardPage;
