import React, { useState, useEffect, useRef } from "react";
import io from "socket.io-client";
import axios from "axios";
import {
  Window,
  WindowContent,
  WindowHeader,
  ProgressBar,
  Button,
  Counter,
  GroupBox,
  NumberInput,
} from "react95";
import GlobalJackpotDisplay from "../components/GlobalJackpotDisplay";
import { useWallet } from "@solana/wallet-adapter-react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import CompetitionStyles from "../styles/CompetitionStyles";

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL || "";

// Styled components
const GlobalJackpotPageWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const JackpotWindow = styled(Window)`
  height: auto;
  width: 60%;
  max-width: 800px;
  display: flex;
  flex-direction: column;
  margin-top: 20px;

  @media (max-width: 1290px) {
    width: 98%;
  }
`;

const WindowHeaderStyled = styled(WindowHeader)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 8px;
  margin-bottom: 2%;
`;

const WindowContentStyled = styled(WindowContent)`
  flex-grow: 1;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  align-items: center;

  @media (max-width: 768px) {
    justify-content: center;
  }
`;

const GroupBoxesContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  justify-content: space-around;
  width: 100%;
  margin-top: 20px;
`;

const GroupBoxStyledResponsive = styled(GroupBox)`
  flex: 1;
  min-width: 100px;
  text-align: center;
  p {
    font-size: 2rem;
    font-weight: bold;
  }
`;

// Styled component for the image
const ResponsiveImage = styled.img`
  max-width: 100%;
  height: auto;
  object-fit: contain;
  padding-bottom: 2%;
  margin-bottom: 3%; /* Default margin for larger screens */
`;

// Styled component for the div
const ResponsiveDiv = styled.div`
  width: 100%;

  /* Adjust margin for smaller screens */
  @media (max-width: 768px) {
    margin-top: 10px; /* Increase the margin for smaller screens */
  }

  @media (max-width: 480px) {
    margin-top: 20px; /* Further increase the margin for very small screens */
  }
`;

const ResponsiveDiv2 = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row; // Changed to row to align elements side by side
  align-items: center; // Vertically aligns the elements
  gap: 10px; // Adds space between GroupBox and Button
  margin-top: 20px;
  justify-content: center;

  @media (max-width: 480px) {
    flex-direction: column; // Changed to row to align elements side by side
  }
`;

const GlobalJackpotPage = () => {
  // State variables
  const [globalJackpot, setGlobalJackpot] = useState(0);
  const [userData, setUserData] = useState(null);
  const [timeString, setTimeString] = useState("");
  const [totalParticipants, setTotalParticipants] = useState(0);
  const [totalTickets, setTotalTickets] = useState(0);
  const { publicKey } = useWallet();
  const navigate = useNavigate();
  const [counterSize, setCounterSize] = useState("lg");

  const [ticketAmount, setTicketAmount] = useState(1);
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [purchaseType, setPurchaseType] = useState("buy"); // 'buy' or 'topOff'

  // Socket.IO reference
  const socketRef = useRef(null);

  // Fetch functions
  const fetchGlobalJackpot = async () => {
    try {
      const response = await axios.get(`${API_BASE_URL}/api/global-jackpot`);
      setGlobalJackpot(response.data.totalJackpot);
    } catch (error) {
      console.error("Error fetching global jackpot:", error);
    }
  };

  const fetchGlobalJackpotStats = async () => {
    try {
      const response = await axios.get(
        `${API_BASE_URL}/api/global-jackpot/stats`
      );
      setTotalParticipants(response.data.totalParticipants);
      setTotalTickets(response.data.totalTickets);
    } catch (error) {
      console.error("Error fetching global jackpot stats:", error);
    }
  };

  const fetchUserData = async () => {
    if (!publicKey) return;

    try {
      const response = await axios.get(
        `${API_BASE_URL}/api/users/${publicKey.toString()}/global-jackpot`
      );
      setUserData(response.data);
    } catch (error) {
      console.error("Error fetching user data:", error);
    }
  };

  // Update the size based on screen width
  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth <= 768) {
        setCounterSize("md"); // Use 'md' for smaller screens
      } else {
        setCounterSize("lg"); // Use 'lg' for larger screens
      }
    };

    // Initial check on load
    handleResize();

    // Add event listener to track window resize
    window.addEventListener("resize", handleResize);

    // Cleanup the event listener on component unmount
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  // useEffect hooks
  useEffect(() => {
    fetchGlobalJackpot();
  }, []);

  useEffect(() => {
    fetchGlobalJackpotStats();
  }, []);

  useEffect(() => {
    fetchUserData();
  }, [publicKey]);

  useEffect(() => {
    // Initialize Socket.IO connection
    socketRef.current = io(API_BASE_URL);

    // Listen for globalJackpotUpdate event
    socketRef.current.on("globalJackpotUpdate", handleGlobalJackpotUpdate);

    return () => {
      // Cleanup on unmount
      socketRef.current.disconnect();
    };
  }, []);

  const handleGlobalJackpotUpdate = (data) => {
    console.log("Global jackpot updated:", data.totalJackpot);
    setGlobalJackpot(data.totalJackpot);
  };

  // Handlers
  const handleInputChange = (value) => {
    if (!isNaN(value) && value > 0) {
      setTicketAmount(value);
    } else {
      setTicketAmount(1);
    }
  };

  const handleBuyClick = () => {
    setPurchaseType("buy");
    setShowConfirmation(true);
  };

  const handleTopOffClick = () => {
    setPurchaseType("topOff");
    setShowConfirmation(true);
  };

  const handleConfirmBuy = async () => {
    setShowConfirmation(false);

    if (!publicKey) {
      alert("Please connect your wallet to proceed.");
      return;
    }

    try {
      const payload = {
        user_id: publicKey.toBase58(),
      };

      if (purchaseType === "buy") {
        payload.ticket_count = ticketAmount;
      } else if (purchaseType === "topOff") {
        payload.topOff = true;
      }

      const response = await axios.post(
        `${API_BASE_URL}/api/global-jackpot/purchase`,
        payload
      );

      if (response.status === 201) {
        alert("Purchase successful!");
        // Refresh data
        fetchGlobalJackpot();
        fetchGlobalJackpotStats();
        fetchUserData();
      } else {
        alert("Purchase failed. Please try again.");
      }
    } catch (error) {
      console.error("Error making purchase:", error);
      alert("Error making purchase. Please try again.");
    }
  };

  const handleCancelBuy = () => {
    setShowConfirmation(false);
  };

  // Timer logic
  useEffect(() => {
    const jackpotEndTime = new Date("2025-02-28T23:59:59Z"); // Adjusted date to be valid

    const updateTimer = () => {
      const now = new Date();
      const distance = jackpotEndTime - now;

      if (distance < 0) {
        setTimeString("00000000");
        clearInterval(timerInterval);
        return;
      }

      const totalDays = Math.floor(distance / (1000 * 60 * 60 * 24));
      const hours = Math.floor(
        (distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
      );
      const minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
      const seconds = Math.floor((distance % (1000 * 60)) / 1000);

      const daysString = totalDays.toString().padStart(3, "0");
      const hoursString = hours.toString().padStart(2, "0");
      const minutesString = minutes.toString().padStart(2, "0");
      const secondsString = seconds.toString().padStart(2, "0");

      const timeString = `${daysString}${hoursString}${minutesString}${secondsString}`;

      setTimeString(timeString);
    };

    updateTimer();
    const timerInterval = setInterval(updateTimer, 1000);

    return () => clearInterval(timerInterval);
  }, []);

  // Cost calculations
  const PROTOCOL_FEE_PERCENTAGE = 5; // Ensure this matches the backend
  const ticketPrice = 1; // 1 SOL per ticket

  // For regular purchase
  const protocolFee = (
    ticketPrice *
    ticketAmount *
    (PROTOCOL_FEE_PERCENTAGE / 100)
  ).toFixed(2);
  const totalCost = (
    ticketPrice * ticketAmount +
    parseFloat(protocolFee)
  ).toFixed(2);

  // For top-off
  const topOffAmount = userData
    ? parseFloat((1 - (userData.totalContribution % 1)).toFixed(2))
    : 0;
  const topOffProtocolFee = (
    topOffAmount *
    (PROTOCOL_FEE_PERCENTAGE / 100)
  ).toFixed(2);
  const topOffTotalCost = (
    topOffAmount + parseFloat(topOffProtocolFee)
  ).toFixed(2);

  return (
    <GlobalJackpotPageWrapper>
      <CompetitionStyles />
      <GlobalJackpotDisplay jackpotAmount={globalJackpot} />

      <JackpotWindow>
        <WindowHeaderStyled>
          <span>GlobalJackpotPage.exe</span>
          <Button
            onClick={() => navigate(-1)}
            style={{ marginLeft: "auto", padding: "0 10px" }}
          >
            X
          </Button>
        </WindowHeaderStyled>

        <WindowContentStyled>
          <div style={{ position: "relative" }}>
            <ResponsiveImage
              src={`${API_BASE_URL}/images/blindfaithbanner.png`}
              alt={"blind faith"}
            />

            <div
              style={{
                position: "absolute",
                top: "70%", // adjust this value to move the div above the img
                left: "50%",
                transform: "translateX(-50%)", // centers the div horizontally
                marginBottom: "10px",
                textAlign: "center",
              }}
            >
              <div style={{ display: "flex", justifyContent: "center" }}>
                <Counter
                  value={timeString}
                  size={counterSize}
                  style={{ textAlign: "left" }}
                />
              </div>
            </div>
          </div>
          <ResponsiveDiv>
            <GroupBoxesContainer>
              <GroupBoxStyledResponsive label="Your Tickets">
                <p>{userData ? Math.floor(userData.tickets) : "?"}</p>
              </GroupBoxStyledResponsive>
              <GroupBoxStyledResponsive label="Total Participants">
                <p>{totalParticipants}</p>
              </GroupBoxStyledResponsive>
              <GroupBoxStyledResponsive label="Total Tickets">
                <p>{totalTickets}</p>
              </GroupBoxStyledResponsive>
              <GroupBoxStyledResponsive label="Winners">
                <p>3</p>
              </GroupBoxStyledResponsive>
            </GroupBoxesContainer>
          </ResponsiveDiv>
          {/* New Buttons and Input */}
          <ResponsiveDiv2>
            <GroupBox className="group-box-styled-2" label="Buy Tickets">
              <NumberInput
                value={ticketAmount}
                step={1}
                min={1}
                max={1000}
                onChange={(value) => handleInputChange(value)}
                width={130}
              />
            </GroupBox>

            <Button
              className="large-button"
              primary
              onClick={handleBuyClick}
              style={{ marginTop: "5px" }} // Add margin-top to the button
            >
              Buy
            </Button>
          </ResponsiveDiv2>

          <p style={{ marginTop: "20px", fontWeight: "bold" }}>
            Progress towards next ticket:
          </p>
          <ProgressBar
            value={
              userData ? ((userData.totalContribution % 1) * 100).toFixed(2) : 0
            }
          />
          <Button fullWidth onClick={handleTopOffClick}>
            Top Off
          </Button>
        </WindowContentStyled>
      </JackpotWindow>

      {/* Confirmation Modal */}
      {showConfirmation && (
        <div className="PopupWrapper">
          <Window
            className="window"
            style={{ maxWidth: "400px", width: "95%", minHeight: 200 }}
          >
            <WindowHeader className="window-header-styled">
              <span>ConfirmPurchase.exe</span>
              <Button
                onClick={handleCancelBuy}
                style={{ marginLeft: "auto", padding: "0 10px" }}
              >
                X
              </Button>
            </WindowHeader>
            <WindowContent>
              <div className="group-boxes-container3">
                {purchaseType === "buy" && (
                  <>
                    <GroupBox
                      className="group-box-styled"
                      label="Total Tickets"
                    >
                      <p>{ticketAmount}</p>
                    </GroupBox>
                    <GroupBox className="group-box-styled" label="Protocol Fee">
                      <p>{protocolFee} SOL</p>
                    </GroupBox>
                    <GroupBox className="group-box-styled" label="Total Cost">
                      <p>{totalCost} SOL</p>
                    </GroupBox>
                  </>
                )}
              </div>
              <div className="group-boxes-container3">
                {purchaseType === "topOff" && (
                  <>
                    <GroupBox
                      className="group-box-styled"
                      label="Top Off Amount"
                    >
                      <p>{topOffAmount} SOL</p>
                    </GroupBox>
                    <GroupBox className="group-box-styled" label="Protocol Fee">
                      <p>{topOffProtocolFee} SOL</p>
                    </GroupBox>
                    <GroupBox className="group-box-styled" label="Total Cost">
                      <p>{topOffTotalCost} SOL</p>
                    </GroupBox>
                  </>
                )}
              </div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  gap: "10px",
                  marginTop: "20px",
                }}
              >
                <Button onClick={handleConfirmBuy} style={{ width: "100px" }}>
                  Confirm
                </Button>
              </div>
            </WindowContent>
          </Window>
        </div>
      )}
    </GlobalJackpotPageWrapper>
  );
};

export default GlobalJackpotPage;
